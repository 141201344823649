<template>
    <div>
        <div class="mainDivStyle text-center">
            <div class="firsLayerImageSection">
                <div class="moveImageForAnimation">
                <div v-if="showImage" style="width:120px;margin-top:16px;">
                    <img style="object-fit:cover;object-position: center;height:100%;width:100%;" :src="image" alt="">
                </div>
                </div>
                <p class="merchant-header-text">Exclusive CanPay Member Benefit</p>
            </div>
            <div>
                <p class="control-margin pt-4 merchant-desc-text">Save big on your Med Card</p>
                <p class="control-margin pb-4 merchant-desc-text">renewal through NuggMD.</p>
                <button class="learn-more-button" @click="redirectTo()">Learn More
                    <svg class="learn-more-arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 42" fill="none">
                    <path d="M24.0047 19.3612L5.31415 0.67097C4.88186 0.23834 4.30479 0 3.68948 0C3.07417 0 2.4971 0.23834 2.06481 0.67097L0.688385 2.04706C-0.207266 2.94373 -0.207266 4.40109 0.688385 5.2964L16.3833 20.9913L0.67097 36.7036C0.238681 37.1362 0 37.713 0 38.3279C0 38.9436 0.238681 39.5203 0.67097 39.9533L2.0474 41.329C2.48003 41.7617 3.05676 42 3.67207 42C4.28738 42 4.86445 41.7617 5.29674 41.329L24.0047 22.6218C24.438 22.1878 24.676 21.6083 24.6746 20.9923C24.676 20.3739 24.438 19.7948 24.0047 19.3612Z" fill="white"/>
                    </svg>
                </button>
                  <p class="merchant-header-text-1 alignSpinTextPostion" style="text-decoration:underline;" @click="initiateAnimation()">I'll check it out later. Spin the Wheel.</p>
            </div>
        </div>
        <div v-if="!showImage && !showImage1" class="findPostion">
            <div style="display:flex;justify-content:center;align:item:center;">
                <div style="width:120px;margin-top:16px;">
                    <img style="object-fit:cover;object-position: center;height:100%;width:100%;" :src="image" alt="">
                </div>
            </div>
        </div>
        <div v-if="!showImage && showImage1" class="findPostion1">
            <div style="display:flex;justify-content:center;align:item:center;">
                <div class="fixingImagePosition" style="width:120px;margin-top:16px;">
                    <img class="finalImageCss" style="object-fit:cover;object-position: center;height:100%;width:100%;" :src="image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
name:"RewardWheelNuggMdCustomModal",
props:{
    image:{
        type:String
    },
    showButton:{
        type:Function
    },
    commandOverSpinValue:{
        type:Function,
        require:true
    }
},
data(){
    return {
        startRewardWheelModalTiming:2000,
        startImageMoving:2000,
        startShrinkAnimation:2000,
        startSmaalShrinkAnimationTime:2000,
        startTranslateYAnimationTime:1000,
        showImage: true,
        showImage1:false
    }
},
mounted(){
    if(window.innerWidth<410){
        const accessMainDivStyle = document.querySelector(".mainDivStyle");
        accessMainDivStyle.style.width = "330px"
    }
},
methods:{
    redirectTo(){
    let self = this;
    self.$root.$emit("rewardWheelPageColor", {
        image: "",
        is_image: false,
        color_1: "",
        color_2: "",
        color_3: ""
    });
    localStorage.setItem("coupon_code",self.coupon_code)
    self.$router.push({path: "/memberbenefit", params: { coupon_code: self.coupon_code }});
    },
    initiateAnimation(){
        var self = this;
        self.showButton();
            const merchantHeaderText = document.querySelector(".merchant-header-text");
            const merchantHeaderText1 = document.querySelector(".merchant-header-text-1");
            const merchantDescText = document.querySelectorAll(".merchant-desc-text");
            const learnMoreButton = document.querySelector(".learn-more-button");
            const firsLayerImageSection = document.querySelector(".firsLayerImageSection");
            const moveImageForAnimation = document.querySelector(".moveImageForAnimation");
            learnMoreButton.style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
            merchantDescText[0].style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
            merchantDescText[1].style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
            merchantHeaderText.style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
            merchantHeaderText1.style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
        setTimeout(()=>{
            learnMoreButton.style.display = "none";
            merchantHeaderText.style.display = "none";
            merchantHeaderText1.style.display = "none"; 
            merchantDescText[0].style.display = "none";
            merchantDescText[1].style.display = "none";
            firsLayerImageSection.style.backgroundColor = "#153458";
            moveImageForAnimation.style.animation = `merchant-svg-frame ${self.startImageMoving}ms linear alternate infinite`;
            setTimeout(() => {
                self.showImage = false;
                setTimeout(()=>{
                    const findPosition = document.querySelector(".findPostion");
                    const topImage1 = findPosition.getBoundingClientRect().top;
                    const topImage2 = moveImageForAnimation.getBoundingClientRect().top;
                    findPosition.style.top = (topImage2 - topImage1+10)+"px";
                    findPosition.style.right = "3px";
                },1);
                moveImageForAnimation.style.animationPlayState = "paused";
                const mainDivStyleAnimation = document.querySelector(".mainDivStyle");
                // mainDivStyleAnimation.style.borderTop = "4px solid #ffffff";
                // mainDivStyleAnimation.style.borderBottom = "4px solid #ffffff";
                // firsLayerImageSection.style.borderTop = "1px solid #ffffff";
                // mainDivStyleAnimation.style.borderRadius = "25px";
                // firsLayerImageSection.style.borderRadius = "25px";
                mainDivStyleAnimation.style.animation = `startShrinkAnimation ${self.startShrinkAnimation}ms linear alternate infinite`;
                setTimeout(() => {
                    mainDivStyleAnimation.style.animationPlayState = "paused";
                    firsLayerImageSection.style.display = "none";
                    mainDivStyleAnimation.style.animation = `startSmallShrinkAnimation ${self.startSmaalShrinkAnimationTime}ms linear alternate infinite`;
                    // mainDivStyleAnimation.style.borderTop = "16px solid #ffffff";
                    // mainDivStyleAnimation.style.borderBottom = "16px solid #ffffff";
                    setTimeout(() => {
                    // mainDivStyleAnimation.style.borderLeft = "4px solid #ffffff";
                    // mainDivStyleAnimation.style.borderRight = "4px solid #ffffff";
                    },self.startSmaalShrinkAnimationTime/10)
                    setTimeout(() => {
                        mainDivStyleAnimation.style.animationPlayState = "paused";
                        self.showImage1 = true;
                        setTimeout(()=>{
                            const findPosition1 = document.querySelector(".findPostion1");
                            const finalImageCss =document.querySelector(".finalImageCss");
                            findPosition1.style.top = (mainDivStyleAnimation.getBoundingClientRect().top - findPosition1.getBoundingClientRect.top)+"px";
                            findPosition1.style.animation = `startTranslateYAnimation1 ${self.startTranslateYAnimationTime}ms linear alternate infinite`;
                            finalImageCss.style.marginLeft = "3px";
                            // mainDivStyleAnimation.style.borderTop = "14px solid #ffffff";
                            // mainDivStyleAnimation.style.borderBottom = "14px solid #ffffff";
                            // mainDivStyleAnimation.style.borderTop = "14px solid #ffffff";
                            // mainDivStyleAnimation.style.borderBottom = "14px solid #ffffff";
                            // mainDivStyleAnimation.style.borderRadius = "50px";
                            mainDivStyleAnimation.style.animation = `startTranslateYAnimation ${self.startTranslateYAnimationTime}ms linear alternate infinite`;
                            setTimeout(()=>{
                                const fixingImagePositionDiv = document.querySelector(".fixingImagePosition");
                                fixingImagePositionDiv.style.marginTop = "11px!important";
                            },100)
                            setTimeout(() => {
                                mainDivStyleAnimation.style.animationPlayState = "paused";
                                findPosition1.style.animationPlayState = "paused";
                                this.commandOverSpinValue(true);
                            },self.startTranslateYAnimationTime)
                        },100);
                    },self.startSmaalShrinkAnimationTime)
                },self.startShrinkAnimation);
            },self.startImageMoving/2);
        },self.startRewardWheelModalTiming);
     
    }
}
}
</script>
<style scoped>
.fixingImagePosition{
    width:120px;
    margin-top:16px;
}
.findPostion{
position:absolute;
z-index:99999999;
width:100%;
}
.findPostion1{
position:absolute;
z-index:99999999;
width:100%;
}
.firstHiddenImage{
    display: flex; 
    justify-content: center; 
    align-items: center;
    position:absolute;
    top:50%;
    width:100%;
}
.mainDivStyle{
    width:350px; 
    height:290px;
    position:absolute;
    z-index:999999;
    transform:translate(-50%, -50%);            
    top: 45%;
    left: 50%;
    background-color:#153458;
    border-radius:14px;
    border: 4px solid #ffffff;
}
.firsLayerImageSection{
    display: flex; 
    justify-content: center; 
    align-items: center;
    width:100%;
    border-radius:14px 14px 0px 0px;
    background-color:#2c4869;
    padding-top:25px;
    padding-bottom:15px;
}
.merchant-header-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:15px;
    margin:0px;
    position:absolute;
    top:10px;
}
.merchant-header-text-1{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:15px;
    margin:0px;
}
.control-margin{
    margin-bottom:0px;
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.learn-more-button {
  align-items: center;
  appearance: none;
  width:200px;
  background-color: #d65e67;
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  padding:5px 0px 5px 0px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.learn-more-button:active,
.learn-more-button:focus {
  outline: none;
}

.learn-more-button:hover {
  background-position: -20px -20px;
}

.learn-more-button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}
.learn-more-arrow{
    position: absolute;
    right:15px;
}
.alignSpinTextPostion{
    position:absolute;
    bottom:25px;
    width: 100%;
}
</style>
